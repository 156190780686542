
@font-face {
	font-family: 'KaiTi';
	src: url(./font/KaiTi.ttf);
}

.App {
  background-image: url(./img/bg2.gif);
  /*font-family: Arial, Helvetica, sans-serif;*/

}

.App .jp-part,
.App .en-part
{
  font-family: Arial, Helvetica, sans-serif;
  /*font-family: Arial, Helvetica, sans-serif;*/
}

.App .cn-part
{
  font-family: KaiTi;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/*
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
*/

html,body{
  height:100%;
}

h2
{
  color:#003366;
  text-align: center;
}

h2 span
{
  color:#003366;
  font-size: 18px;
}

h4
{
  color:#003366;
  margin: 20px 0 10px;
}

h4 span
{
  color:#69696A;
  font-weight: normal;
}

hr
{
  border: 1px dotted #0066CC;
}

.position_center
{
  text-align: center;
}

table,
table tr,
table td
{
  border: gray 1px solid;
  border-collapse: collapse;
  padding: 5px;
}

table tr.colored
{
  background-color:#D4DEF8;
}

.youtube,
.googleMap {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
}
.youtube iframe,
.googleMap iframe {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.App-main
{
  /*background-color: #fff;*/
  max-width: 1000px;
  margin: 0 auto;
  height: 100%;
}

.App-main a
{
  text-decoration: none;
}

.left-hand{

  width: 20%;
}

@media (min-width: 770px)
{
  .right-hand
  {
    width: 80%;
    padding-left: 1px;
  }
}


@media (max-width: 770px)
{
  .right-hand
  {
    width: 100%;
    padding-left: 1px;
  }
}

.App-header {
  background-color: #fff;

  display: block;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  font-size: 0; 
  border-bottom: solid 3px #6699FF;
}

.App-header1 .header1-left a:hover{
  color: #FF6600;
}

.App-header table{
  border:1px solid #ddd;
  border-collapse: collapse;
  border-spacing: 0;
}


@media (min-width: 770px)
{
  .App-header0 {
    padding-top: 10px;
  }
}

@media (max-width: 770px)
{
  .App-header0 {
    padding: 90px 10px 5px;
    word-break: keep-all;
  }
}

.App-header0 {
  display: block;

  font-size: 12px;
  text-align: center;
}

.App-header0 a {
  color: #69696A;
  text-decoration: none;
}

.App-header1 {
  justify-content: space-between;
  color: #6699FF;
  background: #ddd;
  padding-top: 1px;
}

@media (min-width: 770px)
{
  .App-header1 {
    display: flex;
  }
}


@media (max-width: 770px)
{
  .App-header1 {
    display: block;
  }
  .menu
  {
    display: none;
  }
  .App-header1 .header1-left
  {
    width: 100%;
    text-align: center;
  }
  .App-header1 .header1-left a
  {
    display: block;
    margin: 10px;
  }
}

.App-header1 div{
  display: inline-block;
}
.App-header1 .header1-left{
  text-decoration: none;
  margin: 0 auto;
  background-color: #fff;
}

.header1-left-content
{
  text-align: center;
  padding: 5px;
}

.App-header1 .header1-left a{
  text-decoration: none;
  color: #0066CC;
  font-size: 1.5vh;
}

.App-header1 .header1-left .language_buttons
{
  margin: 10px 0;
}

.App-header1 .header1-left .language_buttons a
{
  margin: 0 5px;
  display: inline-block;
}

.App-header1 .header1-right{
  height: auto;
  font-size: 0; 
}

@media (min-width: 770px)
{
  .header-logo{
    height: auto;
    width: 100%;
    margin-top: 30px;
  }
}


@media (max-width: 770px)
{
  .header-logo{
    height: auto;
    width: 30vw;
    margin-top: 10px;
    display:none; 
  }
}

.header-logo-mobile
{
  padding: 10px;
  height: 80%;
}

.header-banner{
  height: auto;
  width: 100%;
  padding: 0;
}

.App-link {
  color: #61dafb;
}

.body{
  max-width: 850;
  background-color: #ddd;
}

.body-part
{
  overflow: hidden;
  display: flex;
  background-color: #ddd;
}

@media (min-width: 770px)
{
  .sidebar{
    background-image: url(./img/bg01.gif);
    background-color: #69696A;
    display: inline-flex;
    height: auto;
    font-size: 10px;
    padding-bottom: 10000px;
    margin-bottom: -10000px;
  }
}


@media (max-width: 770px)
{
  .sidebar
  {
    display: none;
  }
}

.sidebar img{
  width:100%;
  max-width: 100%;
  height: auto;
}

.sidebar .sidebar-logo
{
  margin: 5px auto;
  height: auto;
  width: 100%;
}

.sidebar .sidebar-text-orange
{
  color: #FF6600;
  font-size: 10px;
  margin: 10px auto;
}

.sidebar .sidebar-text-black
{
  font-size: 12px;
  margin: 2px auto;
}

.sidebar .sns-icon
{
  width: auto;
  display: flex;
  /*margin: 10px auto ;*/
}

.sidebar .sns-icon .sidebar-sns-logo
{
  width: 80%;
  margin: 10px;
}

.main-part
{
  background-color: #ddd;
  display: inline-flex;
  height: 100%;
  vertical-align: top;
}

.sidebar .body-content
{
  padding: 10px;
  width: 100%;
}

.main-part .body-content
{
  padding: 20px;
  background-color: #fff;
  box-sizing: border-box;
}

.body-content a
{
  font-size: 15px;
  color: #0066CC;
  font-weight: bold;
}

.body-content button a
{
  font-size: 15px;
  color: #000;
  font-weight: normal;
}

.body-content button a:hover
{
  font-size: 15px;
  color: #000;
  font-weight: normal;
}

.body-content a:hover
{
  color: red;
}

.dotline_ora
{
  border-bottom: 1px dotted #0066CC;
  margin: 10px 0 20px;
}

.body-content p
{
  font-size: 15px;
  color: #69696A;
}

.width_200px
{
  width: 200px;
  
}

.full-width
{
  width: 100%;
}

.width-60
{
  width: 60%;
}

.width-50
{
  width: 50%;
}

.width-40
{
  width: 40%;
}

.float_center
{
  text-align: center;
  margin: 0 10px 0 10px;
}

@media (min-width: 770px)
{
  .float_right
  {
    float: right;
    margin: 0 10px 0 10px;
  }
  .float_left
  {
    float: left;
    margin: 0 10px 0 10px;
  }
}

@media (max-width: 770px)
{
  .float_right,
  .float_left
  {
    margin: 10px;
    text-align: center;
  }
}

.textAlignCenter
{
  text-align: center;
}

.main-part .body-content
{
  widtH:100%;
  min-height: 800px;
}

.main-part .body-content div
{
  font-size: 15px;
  font-weight: bold;
  line-height: 25px;
}

.main-part .content
{
  margin: 20px 0;
  color: #0066CC;
  border-left: 2px solid #3399FF;
  padding: 0 10px;
}

.main-part .content p
{
  padding: 10px 0;
  line-height: 20px;
  color: #0066CC;
  line-height: 25px;
}

.main-part .content a
{
  color: #0066CC;
}

.main-part .content a
{
  color: #69696A;
}


.main-part .bglb,
.main-part .bglb th,
.main-part .bglb td 
{
  border:1px solid grey;
  border-collapse: collapse;
  font-size: 13px;
  color:#69696A;
  text-align: center;
}

.main-part .bglb
{
  margin: 0 auto;
  width: 100%;
}

.main-part .bglb th
{
  font-weight: normal;
  background-color: #E4EBFF;
  padding: 5px;

}

.main-part .bglb td
{
  padding: 5px;
}

.main-part p a:before
{
  content: '';
  background-image: none;
  margin: 0;
  width: 0;
  height: 0;
}

.main-part a:before
{
  content: '';
  display: inline-block;
  background-image: url(./img/icon/link-icon.png);
  display: inline-block;
  width: 11px;
  height: 11px;
  background-size: contain;
  vertical-align: middle;
  filter: invert(15%) sepia(95%) saturate(6932%) hue-rotate(358deg) brightness(90%) contrast(112%);
  margin: 0 5px 2px 0;
}

.main-part .content-bg-blue
{
  background-color: #3399FF;
  color: #fff;
  border-left: 5px solid #0066CC;
  padding: 5px;
  margin: 30px 0 20px;
}

.main-part .content-bg-border
{
  border-bottom: 1px dotted #999999;
  border-left: 3px solid #cccccc;  padding: 5px;
  margin: 30px 0 20px;
  color: #69696A;
}

.main-part .content-pic
{
  max-width: 100%;
  vertical-align: middle;
}

.main-part .inline-block-2
{
  width: 80%;
  display: inline-block;
  vertical-align: top;
  padding: 10px;
  margin: 0 auto;
  text-align: left;
}

.main-part .inline-block-2 p
{
  max-width: 80%;
  margin: 0 auto;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.footer
{
  background-color: #E1E7F9;
  text-align: center;
  padding: 30px 0;
}

.footer a
{
  color:#0066CC;
  font-size: 12px;
  text-decoration: none;
}

.footer a:hover
{
  color: #FF6600;
}

.text_indent
{
  margin-left: 17px;
}

/* DropDown menu */
.menu {
  position: relative;
  width:　100%;
  height: 40px;
  margin: -5px auto;
  padding: 0;
  list-style: none;
  font-size: 11px;
  background-color: #fff;
}

.menu > li {
  list-style: none;
  display: block;
  float: left;
  width: 16.5%;
  margin: 0 .1%;
  height: 40px;
  background: url(./img/menu_bg.gif) 0 0 repeat-x;
}

.menu.en-part > li {
  width: 19.8%;
}

.menu > li:nth-child(6n+1){
  margin: 0 .1% 0 0;
}

.menu > li:nth-child(6n){
    margin: 0 0 0 .1%;
}  

.menu > li a {
  display: block;
  color: #0066CC;
  font-weight: bold;
  text-decoration: none;
  word-wrap: break-word;
  line-height: 40px;
  font-size: .78rem;
}

.menu > li a:hover {
  color: #FF6600;
  line-height: 40px;
}

ul.menu__second-level {
  list-style: none;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  z-index: 1;
}

ul.menu__third-level {
  visibility: hidden;
  opacity: 0;
}

ul.menu__fourth-level {
  visibility: hidden;
  opacity: 0;
}

.menu > li:hover {
  background: #EBF0FF;
  -webkit-transition: all .5s;
  transition: all .5s;
}

.menu__second-level li {
  border-top: 1px solid #fff;
}

.menu__third-level li {
  border-top: 1px solid #fff;
}

.menu__second-level li a:hover {
  background: #EBF0FF;
}

.menu__third-level li a:hover {
  background: #EBF0FF;
}

.menu__fourth-level li a:hover {
  background: #EBF0FF;
}

/* 下矢印 */
/*
.init-bottom:after {
  content: '';
  display: inline-block;
  width: 6px;
  height: 6px;
  margin: 0 0 0 15px;
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
*/

/* floatクリア */
.menu:before,
.menu:after {
  content: " ";
  display: table;
}

.menu:after {
  clear: both;
}

.menu {
  *zoom: 1;
}

.menu > li.menu__single {
  position: relative;
  text-align: center;
}

.menu > li.menu__single a.init-bottom Link
{
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform : translate(-50%,-50%);
  transform : translate(-50%,-50%);
  width: 100px;
  text-align: center;
  line-height: 13px;
}

li.menu__single ul.menu__second-level {
  position: absolute;
  top: 30px;
  width: 100%;
  background: #EBF0FF;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  padding: 0;
}

li.menu__single:hover ul.menu__second-level {
  top: 40px;
  visibility: visible;
  opacity: 1;
}

.br::before {
  content: "\A" ;
  white-space: pre ;
}

/* Styles for burger menu*/

.mobile_header
{
  width: 100%;
  background-color: rgba(255, 255, 255, 1);
  height: 80px;
  position: fixed;
  z-index: 1;
}

@media (min-width: 770px)
{
  .mobile_header
  {
    display: none;
  }
}

@media (max-width: 770px)
{

}

.menu-item-main
{
  width: 100%;
  border-bottom: 1px solid #69696A;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 26px;
  top: 26px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #0066CC;
  opacity: 1 !important;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #0066CC;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  top: 0;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  /*padding: 2.5em 1.5em 0;*/
  padding: 30px 10px 10px;
  font-size: 20px;
}

.bm-menu a{
  color: #fff;
  padding: 10px 0;
}

.bm-menu ,
.Collapsible
{
  font-size: 18px;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;

  color: white;
}

.bm-item a
{
  color: white;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  top:0;
}

.Collapsible
{
}

.Collapsible li
{
  padding: 10px;
  list-style: none;
}

.Collapsible li a
{
  font-size: 13px;
}

.Collapsible__trigger
{
  padding: 10px 0;
  display: block;
  cursor:pointer;
  width: 100%;
}

.Collapsible .Collapsible__trigger.is-closed:after {
  /*content: '\002B'; /* Unicode character for "plus" sign (+) */
  content: ' '; 
  background: url("./img/mobile_menu/icon_down.png") top center / cover no-repeat;
  filter: invert(88%) sepia(61%) saturate(0%) hue-rotate(229deg) brightness(107%) contrast(101%);
  font-size: 30px;
  color: white;
  float: right;
  font-weight: bold;
  padding-right: 5px;
  line-height: 80%;
  width:20px;
  height:20px;
}

.Collapsible .Collapsible__trigger.is-open:after {
  /*content: "\2212"; /* Unicode character for "minus" sign (-) */
  content: ' '; 
  background: url("./img/mobile_menu/icon_up.png") top center / cover no-repeat;
  filter: invert(88%) sepia(61%) saturate(0%) hue-rotate(229deg) brightness(107%) contrast(101%);
  font-size: 30px;
  float: right;
  font-weight: bold;
  padding-right: 5px;
  line-height: 80%;
  width:20px;
  height:20px;
}

a.mobile_menu_icon
{
  display: inline;
}

a.menu-banner .sidebar-icon{
  padding: 10px;
  width: 50px;
  margin: 0 9%;
}

a.bm-item.menu-banner
{
  text-align: center;
}

div.bm-item .sidebar-sns-logo
{
  padding: 10px;
}


/* Contact Form */
.form_required_icon
{
  color: red !important;
  font-weight: bold;
  margin-left: 10px;
}

.form_required
{
  color: red !important;
  font-weight: bold;
}

.contact
{
  max-width:600px;
  width: 100%;
}

p.contact_label
{
  margin: 20px auto 0px;
}

.contact_confirm p
{
  font-size: 14px;
}

.contact_confirm span
{
  color: #2b2b2b;
  white-space: pre-wrap;
}

.contact input,
.contact textarea,
.contact select
{
  font-size: 14px;
}


.contact .input_long
{
  width: 100%;
  max-width:600px;
}

.contact textarea {
  resize: none;
  max-width:600px;
  width: 100%;
  height:100px;
}

.contact textarea.contact_content {
  resize: none;
  max-width:600px;
  width: 100%;
  height:200px;
}

p.send_button {
  text-align: center;
}

p.send_button button{
  font-size: 14px;
  margin: 0 20px;
}

/* Facebook Page*/
.facebook_page
{
  text-align: center;
}

.fb_iframe_widget,
.fb_iframe_widget span,
.fb_iframe_widget iframe
{
  width:100% !important;
  max-width: 500px;
}

._2p3a
{
  width:100% !important;
}

.hide
{
  /*z-index: -1; */ 
  display: none;
}

/* citizenship */
.img_citizenship0
{
  width: 45%;
  padding: 2.5%;
}

.img_citizenship1
{
  width: 100%;
  padding: 2.5%;
}

.citizenship-half
{
  width: 100%;
  display: inline;
}

.citizenship-half div
{
  vertical-align: top;
}

.citizenship-half0
{
  display: inline-block;
  width: 40%;
  margin: 0 5%;
}

.citizenship-half1
{
  text-align: center;
  display: inline-block;
  width: 35%;
  max-width: 250px;
  margin: 0 2.5%;
}

.about_us_pics
{
  margin: 0 auto;
  vertical-align: middle;
}

.about_us_pics img
{
  padding: 1%; 
  margin: 0 0.5%;
  width: 30%;
  max-width: 40%;
  height: auto;
}

.seminer_pics
{
  margin: 0 auto;
  vertical-align: middle;
  text-align: center;
}

@media (min-width: 920px)
{
  .seminer_pics img
  {
    padding: 1%; 
    margin: 0 1%;
    width: auto;
    max-width: 100%;
    max-height: 300px;
  }
}

@media (max-width: 920px)
{
  .seminer_pics img
  {
    padding: 1%; 
    margin: 0 1%;
    width: 80%;
    height: auto;
  }
}